<template>
    <LiefengContent>
        <template v-slot:title>
            排行榜
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :total="total"
                :curPage="currentPage"
                :pagesizeOpts="[20, 30, 50, 100]"
                @hadlePageSize="pageChange"
                :loading="loading"
                :fixTable="true"
                :pageSize="pageSize"
                :showIndex="false"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/answerwinning')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
export default {
    data() {
        return {
            loading: false,
            //表头列表
            talbeColumns: [],
            tableData: [],
            total: 0,
            currentPage: 1,
            pageSize: 20,

        }
    },
    methods: {
        // 返回
        clickBack(){
            var index = parent.layer.getFrameIndex(window.name) 
            if (index) {
                //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else {
                this.$router.push('/newanswerindex?menuId=' + this.$route.query.menuId)
            }
           
        },
        //分页器
        async pageChange(data) {
            this.loading = true
            await this.$get("/info/api/pc/information/answer/selectAnswerUserRanking", {
                    businessType: '0',
                    businessCode: this.$route.query.id,
                    orgCode: parent.vue.oemInfo.oemCode,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    page:this.currentPage,
                    pageSize:this.pageSize
            }).then(res => {
                if (res.code == 200) {
                    this.tableData = res.data.page.dataList
                    this.total = res.data.page.maxCount
                    this.currentPage = res.data.page.currentPage
                    this.pageSize = res.data.page.pageSize
                    this.loading = false
                    this.talbeColumns = [
                        {
                            title: "排行",
                            key: "userRanking",
                            minWidth: 140,
                            align: "center",
                        },
                        {
                            title: "街道",
                            key: "streetName",
                            minWidth: 140,
                            align: "center",
                        },    
                        {
                            title: "社区",
                            key: "communityName",
                            minWidth: 140,
                            align: "center",
                        },    
                        {
                            title: "姓名",
                            key: "userName",
                            minWidth: 140,
                            align: "center",
                        },    
                        {
                            title: "年龄",
                            key: "age",
                            minWidth: 140,
                            align: "center",
                        },    
                        {
                        title: "性别",
                        key: "sex",
                        width: 80,
                        align: "center",
                            render:(h,params) =>{
                                return h('div',{},params.row.sex == 0 ? '未知' :params.row.sex == 1 ?'男':params.row.sex == 2?'女':'未知')
                            }
                        },   
                        {
                            title: "联系电话",
                            key: "mobile",
                            minWidth: 140,
                            align: "center",
                        }, 
                    ]
                    var arr = []
                     if(this.$route.query.type == '1'){
                         arr = [
                             {
                                title: "用时",
                                key: "timeTotal",
                                width: 150,
                                align: "center",
                                render: (h, params) => {
                                    let time = params.row.timeTotal
                                    let h1 = Math.floor(time / 3600)
                                    let m = Math.floor((time / 60) % 60)
                                    let s = Math.floor(time % 60)
                                    return h("span", h1 + "时" + m + "分" + s + "秒")
                                },
                            },   
                         ]
                     }else if(this.$route.query.type == '2'){
                        // 计分
                        arr = [
                            {
                                title: "分数",
                                key: "score",
                                minWidth: 140,
                                align: "center",
                            },   
                             {
                                title: "用时",
                                key: "timeTotal",
                                width: 150,
                                align: "center",
                                render: (h, params) => {
                                    let time = params.row.timeTotal
                                    let h1 = Math.floor(time / 3600)
                                    let m = Math.floor((time / 60) % 60)
                                    let s = Math.floor(time % 60)
                                    return h("span", h1 + "时" + m + "分" + s + "秒")
                                },
                            }, 
                         ]
                    }else if(this.$route.query.type == '3'){
                        // 考试
                        arr = [
                             {
                                title: "分数",
                                key: "score",
                                minWidth: 140,
                                align: "center",
                            },    
                         ]
                    }
                    this.talbeColumns = [...this.talbeColumns,...arr]
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                    this.loading = false
                    return
                }
            })
        },
    },

    created() {
        this.pageChange({
            page: this.currentPage,
            pageSize: this.pageSize,
        })
    },
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
    },
}
</script>
    
<style scoped lang='less'>
.eye {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    margin: 0 15px 0;
}
</style>